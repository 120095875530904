<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1 class="pad-bottom medium-heading">
            Create or Reset Password
          </h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <p>
            Please enter the following details to reset your password.
          </p>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-5">
              <label>New password</label>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-5">
              <input
                v-on:focus="clearMessage"
                v-model="user.password"
                type="password" />
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-5">
              <label>Confirm new password</label>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-5">
              <input
                v-model="user.passwordCheck"
                type="password" />
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-5">
              <button
                v-on:click="resetPassword"
                class="button">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
        <span v-on:click="message = ''" class="close-message">X</span>
        <h3 class="message-text">{{ message }}</h3>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'RequestPassword',
  data() {
    return {
      user: {
        password: '',
        passwordCheck: '',
      },
      message: '',
      failedCheck: false,
    };
  },
  methods: {
    clearMessage() {
      this.message = '';
    },
    resetPassword() {
      this.failedCheck = false;
      if (this.user.password === this.user.passwordCheck) {
        const hashCheck = this.$route.params.hash;
        const userId = this.$route.params.id;
        axios.post(`/users/resetPassword/${hashCheck}/${userId}.json`, this.user)
          .then((response) => {
            this.message = response.data.response;
          });
      } else {
        this.failedCheck = true;
        this.message = 'The passwords you entered to not match. Please check.';
      }
    },
  },
};
</script>
